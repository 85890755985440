/* Primary Colors */
@CAPGEMINI_BLUE: #0070ad;
@VIBRANT_BLUE: #12abdb;
@DEEP_PURPLE: #2b0a3d;
@DARK_GREY: #272936;
@DEFAULT_GREY: #e5e5e5;
@WHITE: #ffffff;

@grey1: rgba(@DARK_GREY, 20%);

/* Cool Colors GREEN */
@COOL_GREEN1: #57cf80;
@COOL_GREEN2: #33b569;
@COOL_GREEN3: #2ea657;
@COOL_GREEN4: #178c3d;
@COOL_GREEN5: #178036;

/* Cool Colors TEAL */
@COOL_TEAL1: #00e6e3;
@COOL_TEAL2: #00d5d0;
@COOL_TEAL3: #00bfbf;
@COOL_TEAL4: #00929b;
@COOL_TEAL5: #007d74;

/* Cool Colors PEACOCK */
@COOL_PEACOCK1: #00e0cb;
@COOL_PEACOCK2: #00b2a2;
@COOL_PEACOCK3: #0f878a;
@COOL_PEACOCK4: #0f6a73;
@COOL_PEACOCK5: #0f434a;

/* Cool Colors SAPPHIRE */
@COOL_SAPPHIRE1: #338091;
@COOL_SAPPHIRE2: #336b7d;
@COOL_SAPPHIRE3: #14596b;
@COOL_SAPPHIRE4: #214554;
@COOL_SAPPHIRE5: #173340;

/* Warm Colors VIOLET */
@WARM_VIOLET1: #e557ad;
@WARM_VIOLET2: #d13a8c;
@WARM_VIOLET3: #ba2980;
@WARM_VIOLET4: #a12980;
@WARM_VIOLET5: #811b6f;

/* Warm Colors YELLOW */
@WARM_YELLOW1: #ffda80;
@WARM_YELLOW2: #ffd068;
@WARM_YELLOW3: #ffb24a;
@WARM_YELLOW4: #ff9c29;
@WARM_YELLOW5: #ff8e12;

/* Warm Colors VELVET */
@WARM_VELVET1: #9e4780;
@WARM_VELVET2: #802b73;
@WARM_VELVET3: #750d5c;
@WARM_VELVET4: #590a42;
@WARM_VELVET5: #42142e;

/* Warm Colors RED */
@WARM_RED1: #ff5770;
@WARM_RED2: #ff455e;
@WARM_RED3: #ff304d;
@WARM_RED4: #e30021;
@WARM_RED5: #a6001a;

@colorWhite: @WHITE;
@colorGreyMid: #d1d1d1;
@colorLink: @CAPGEMINI_BLUE;
@colorGreyLight: #f7f7f7;
@colorBlack: #000000;
@colorTextContent: @DARK_GREY;
@colorPanelHead: @DEEP_PURPLE;
@colorBranding: #eb5f1e;
@colorSecondary: @DEEP_PURPLE;
@colorError: @WARM_RED4;
@colorWarning: @WARM_YELLOW4;
@colorInfoText: #7e7e7e;
@colorPrimary: @CAPGEMINI_BLUE;
@colorPageTitle: @DEEP_PURPLE;
@colorBorders: #d1d1d1;
@colorFilters: #f8f8ff;

// Addons
@colorSuccess: #178036;
@colorGreyMild: #dadada;
@colorGreyCool: #e5e5e5;
@colorGreyWarm: #e7e7e7;
@colorGreyDark: #ababab;
@colorDisabledText: #6b6b6b;

//Nav Bar
@mainHeader: @CAPGEMINI_BLUE; // Appears 6 times
@NavstartColor: @CAPGEMINI_BLUE;
@NavendColor: @CAPGEMINI_BLUE;

// Widget Style Default
@WidgetstartColor: @CAPGEMINI_BLUE;
@WidgetendColor: @DEEP_PURPLE;

/**
 * @font-face LESS Mixin
 * use: .font-face(
 *   @font-family, // name
 *   @file-path,   // absolute/relative URL to font files
 *   @font-weight, // light/normal/bold/inherit | 300/400/700
 *   @font-style   // italic/normal/inherit
 * )
 */

.font-face(@font-family, @file-path, @font-weight, @font-style) {
  @font-face {
    font-family: @font-family;
    src: url("@{file-path}.eot");
    src: url("@{file-path}.eot?#iefix") format("embedded-opentype"),
      url("@{file-path}.woff") format("woff"),
      url("@{file-path}.ttf") format("truetype"),
      url("@{file-path}.svg#@{file-path}") format("svg");
    font-weight: @font-weight;
    font-style: @font-style;
    font-display: swap;
  }
}

/**
   * font LESS Mixin
   * use: .font(
   *   @font-family, // name
   *   @file-size,   // any unit/inherit
   *   @font-weight, // light/normal/bold/inherit | 300/400/700
   *   @font-style   // italic/normal/inherit
   * )
   */

@base-path: "~src";
.font-face(Ubuntu-Light, "@{base-path}/assets/fonts/Ubuntu-Light", 300, normal);
.font-face(Ubuntu-Regular, "@{base-path}/assets/fonts/Ubuntu-Regular", 400, normal);
.font-face(Ubuntu-Medium, "@{base-path}/assets/fonts/Ubuntu-Medium", 500, normal);
.font-face(Ubuntu-Bold, "@{base-path}/assets/fonts/Ubuntu-Bold", 700, normal);

.font(@font-family, @font-size, @font-weight, @font-style) {
  font-family: @font-family;
  font-size: @font-size;
  font-weight: @font-weight;
  font-style: @font-style;
}

@thin: -apple-system, BlinkMacSystemFont, Ubuntu-Light, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@regular: -apple-system, BlinkMacSystemFont, Ubuntu-Regular, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@medium: -apple-system, BlinkMacSystemFont, Ubuntu-Medium, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;
@bold: -apple-system, BlinkMacSystemFont, Ubuntu-Bold, "Segoe UI",
  "Segoe UI Local", "Segoe UI Web (West European)", Roboto, Oxygen, Cantarell,
  "Open Sans", "Helvetica Neue", sans-serif;

.FontThin(@font-size) {
  .font(@thin, @font-size, 300, normal);
}

.FontRegular(@font-size) {
  .font(@regular, @font-size, 400, normal);
}

.FontMedium(@font-size) {
  .font(@medium, @font-size, 500, normal);
}

.FontBold(@font-size) {
  .font(@bold, @font-size, 700, normal);
}

//Border Radius
.borderRadius (@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  -ms-border-radius: @radius;
  -o-border-radius: @radius;
  border-radius: @radius;
}

//Shadow
.box-shadow (@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

// Gradient
.gradient (@startColor, @endColor) {
  background-color: @startColor;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(@startColor),
    to(@endColor)
  );
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

// Transitions
.transitions(@effects) {
  -webkit-transition: @effects;
  -moz-transition: @effects;
  -ms-transition: @effects;
  -o-transition: @effects;
  transition: @effects;
}

// Transform
@transformX: translateX(-50%);
@transformY: translateY(-50%);

.transform(@transform) {
  -webkit-transform: @transform;
  -moz-transform: @transform;
  -ms-transform: @transform;
  -o-transform: @transform;
  transform: @transform;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.alignVertical {
  position: relative;
  .transform(translateY(50%));
}

.alignHorizontal {
  position: relative;
  .transform(translateX(50%));
}

.overflowY {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.text-primary {
  color: @CAPGEMINI_BLUE !important;
}

.text-info {
  color: @VIBRANT_BLUE !important;
}

.text-success {
  color: @colorSuccess !important;
}

.text-warning {
  color: @WARM_YELLOW4 !important;
}

.text-danger {
  color: @WARM_RED4 !important;
}

.bg-primary {
  background-color: @CAPGEMINI_BLUE !important;
  color: @WHITE;
}

.bg-info {
  background-color: @VIBRANT_BLUE !important;
}

.bg-success {
  background-color: @colorSuccess !important;
  color: @WHITE;
}

.bg-warning {
  background-color: @WARM_YELLOW4 !important;
  color: @colorTextContent;
}

.bg-danger {
  background-color: @WARM_RED4 !important;
  color: @WHITE;
}

.bgSecondary {
  background-color: @DEEP_PURPLE !important;
}

.bgDarkGrey {
  background-color: @DARK_GREY !important;
}

.bgMildGrey {
  background-color: @colorGreyMild !important;
}

.bgCoolGrey {
  background-color: @colorGreyCool !important;
}

/*=============================================
=            Inline Borders            =
=============================================*/
.borderInline {
  .box-shadow(inset 0 0 0 1px @colorBorders);
}

.borderInlineLeft {
  .box-shadow(-1px 0 0 0 @colorGreyMid);
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: @regular;
  /*Font Vaiable*/
  font-size: 0.875rem;
  /* Inherit from       :root and set to 14px */
  font-weight: normal;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  color: @colorTextContent;
}

/** In Chrome we can't use the semilight weight of the local web font. Use web fonts instead. **/

body:not(*:root) {
  font-family: -apple-system, BlinkMacSystemFont, Ubuntu, "Segoe UI", Roboto,
    Oxygen, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /*font family Variable*/
}

a {
  text-decoration: none;
}

/*----------  Fonts Weight  ----------*/
.text-bold {
  .FontMedium(1rem);
}

.text-normal {
  line-height: normal;
  .FontRegular(inherit);
}

/*=============================================
=            Main Nav Styles            =
=============================================*/


.navbar {
  background: @mainHeader !important;
  border: none;
  padding: 0 0 0 1rem;
 // min-height: 5rem;
  top: 0;
  position: relative;
//  width: 100%;

}


.skip-main-container {
  position: relative;
}
.skip-main {
  font-size: large;
  background-color: #0070ad;
  color: white;
  text-decoration: underline;
  padding: 0.7em;
}
.skip-main:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  left: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
}


.navbar-brand {
  .FontMedium(1.125rem);
}

.navbar-brand img {
  height: 1.875rem;
}

.navbar-brand h1 {
  .FontRegular(1.5rem);
  margin: 0;
}

/* Main Navigation */

.navbar .nav-item {
  .transitions(all 0.3s ease-in-out);
}

.navbar .nav-item .nav-link {
  line-height: 3.125rem;
  padding: 0 1rem;
  color: @colorWhite;
  text-shadow: none;
  .transitions(all 0.3s ease-in-out);
}

.navbar .nav-item .nav-link i {
  padding-left: 0.625rem;
}

.navbar .nav-item:hover {
  background-color: @colorSecondary;
}

.navbar .nav-item:hover .nav-link {
  color: @colorWhite;
}

.navbar .dropdown-toggle::after {
  border: none;
}

.navbar-expand-md .navbar-nav .dropdown-menu,
.pageMenu .dropdown-menu {
  padding: 0;
  border-radius: 0;
  border: none;
  background: @colorSecondary;
  margin: 0;
  color: @colorWhite;
}

.navbar-nav > .dropdown > .dropdown-menu,
.pageMenu > .dropdown > .dropdown-menu {
  margin: 0;
}

.dropdown-menu li .dropdown-item,
.sub-menu li .dropdown-item,
.pageMenu .dropdown-item {
  color: @colorWhite;
  font-size: 0.813rem;
  padding: 0.625rem 1rem;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.pageMenu .dropdown-item {
  line-height: 1.188rem;
  .FontRegular(0.875rem);
}

.dropdown-menu > li:last-child > .dropdown-item {
  border-bottom: none;
}

.sub-menu {
  left: 100%;
  top: 0;
}

.navbar-expand-md .navbar-nav .dropdown-menu .dropdown-item:hover,
.pageMenu .dropdown-menu .dropdown-item:hover {
  background: @VIBRANT_BLUE;
  color: @DEEP_PURPLE;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

/*----------  Nav Tabs  ----------*/
.nav.nav-tabs {
  height: 3.125rem;
  background-color: @WHITE;
  border-bottom: 0;
  text-align: center;
}

.nav-tabs .nav-item {
  font-size: 1rem;
  font-family: @medium;
  position: relative;
  min-width: 15rem;
}

.nav-tabs .nav-item .nav-link {
  .FontRegular(1rem);
  height: 3.125rem;
  color: @colorTextContent;
  position: relative;
  line-height: 2.063rem;
  border-top: 0;
  .borderRadius(0);
  .transitions(background-color 0.3s ease-in-out);
}

.nav-tabs .nav-item.dropdown:focus .nav-link.active,
.nav-tabs .nav-item.dropdown:hover .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active {
  background-color: @colorWhite;
  border-color: @colorGreyMid @colorGreyMid @colorWhite;
  color: @colorLink;
  border: 0;
  margin-bottom: 1px;
}

.nav-tabs .nav-item.dropdown .nav-link.active {
  line-height: 1rem;
  height: 3.125rem;
}

.nav-tabs .dropdown-toggle::after {
  display: block;
  margin: 0;
}

.nav-tabs .nav-item.dropdown:focus .nav-link,
.nav-tabs .nav-item.dropdown:hover .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent transparent @colorGreyMid;
  background-color: @colorGreyMid;
  color: @colorBlack;
}

.nav-tabs .nav-link.active:after,
.nav-tabs .nav-link.active:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  width: 100%;
  // border-left: 8px solid transparent !important;
  // border-right: 8px solid transparent !important;
  border-bottom: 3px solid @CAPGEMINI_BLUE !important;
  bottom: 0;
  left: 50%;
  .transform(@transformX);
  z-index: 10;
}

.nav-tabs .nav-link.active:before {
  // border-left: 10px solid transparent !important;
  // border-right: 10px solid transparent !important;
  border-bottom: 3px solid @CAPGEMINI_BLUE !important;
}

.nav-tabs .nav-item.dropdown:hover .nav-link.active:after {
  border-bottom: 3px solid @CAPGEMINI_BLUE !important;
}

.nav-tabs .nav-item.dropdown:hover .nav-link.active::before {
  border-bottom: 3px solid @CAPGEMINI_BLUE !important;
}

.nav-tabs .nav-link.dropdown-toggle::after {
  border-top: 0;
}

.nav-tabs .dropdown-menu {
  background: @WHITE;
  border: none;
  width: 100%;
  padding: 0.625rem;
  font-size: 0.875rem;
  z-index: 11;
  .borderRadius(0);
  .box-shadow(0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15));
}

.dropdown-menu,
.inlineDropdown .formDropdown {
  background: @WHITE;
  padding: 0;
  font-size: 0.875rem;
  z-index: 11;
  .borderRadius(0);
  .box-shadow(0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15));
}

.nav-tabs .dropdown-menu li .dropdown-item,
.inlineDropdown .dropdown-item {
  .FontRegular(inherit);
  color: @DARK_GREY;
  padding: 0 1rem;
  line-height: 2.25rem;
  .transitions(all 0.3s ease-in-out);
}

.dropdown-menu > .dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item:focus,
.dropdown-item:hover,
.nav-tabs .dropdown-menu li .dropdown-item:focus,
.nav-tabs .dropdown-menu li .dropdown-item:hover {
  background-color: @DARK_GREY;
  color: @WHITE;
}

.navbar-collapse .dropdown-item.active,
.navbar-collapse .dropdown-item:active,
.nav-tabs .dropdown-menu li .dropdown-item:active,
.nav-tabs .dropdown-menu li .dropdown-item.active {
  background-color: @colorLink;
  color: @colorWhite;
}

.formDropdown .dropdown-menu {
  padding: 1rem;
  line-height: normal;
  min-width: 16.625rem;
  width: max-content;
  top: calc(2.75rem - 0.5px);
  right: -50%;
}

.formDropdown .dropdown-menu .form-check {
  margin-bottom: 0.5rem;
}

.formDropdown .dropdown-menu .form-group {
  margin-bottom: 0;
}

.tabpageName {
  display: block;
  opacity: 0;
  color: @colorTextContent;
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 5rem;
  .transitions(all 0.3s ease-in-out);
}

.nav-tabs .dropdown .nav-link.active + .tabpageName {
  opacity: 1;
  bottom: 0.5rem;
}

.nav-tabs i {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

#tabMain + .tab-content {
  // background: @DEFAULT_GREY url("../IMG/Capgemini-Line.svg") no-repeat;
  background: @DEFAULT_GREY url("~src/assets/images/Capgemini-Line.svg")
    no-repeat;
  background-size: cover;
  .box-shadow(inset 0 10px 10px -10px rgba(0, 0, 0, 0.15));
}

.tab-content {
  background: @WHITE;
  background-size: 100%;
  position: relative;
  height: calc(100% - 3.125rem);
  border-top: 1px solid @colorBorders;
}

.userData + .contentScroll .tab-content {
  background: none;
  .box-shadow(none);
}

.subTabs ul.nav.nav-tabs {
  border: 1px solid @colorGreyMid;
  border-bottom: 0;
}

.subTabs .nav-item:first-child a.nav-link.active {
  border-left-color: transparent;
}

.subTabs .tab-content {
  height: calc(100% - 6.8rem);
}

.subTabs .tab-content.subFilters {
  height: calc(100% - 3.062rem);
}

.modal .tab-content {
  height: auto;
}

.subTabs .tab-content .contentScroll {
  height: 100%;
}

.subTabs .tab-content.subFilters .contentScroll {
  height: calc(100% - 3.625rem);
}

.tab-content > .active {
  height: 100%;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.subTabs .tab-content > .active {
  .borderBox;
  border-top: 0;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.nav-pills .nav-link {
  .borderRadius(0);
}

/* User Profile */

.userProfile .nav-item .nav-link {
  padding: 0 1rem;
}

.userProfile .nav-item:hover {
  background: @colorSecondary;
}

.userProfile .dropdown-menu {
  right: 0;
  left: inherit;
}

/* #Breadcrumb */

.breadcrumb {
  padding: 0.75rem 1rem;
  .borderRadius(0);
  background-color: rgba(@WHITE, 70%);
}

.breadcrumb-item {
  .FontRegular(0.875rem);
}

.breadcrumb-item + .breadcrumb-item::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
}

.breadcrumb-item.active {
  color: @DARK_GREY;
}

/*=============================================
=            Icon Tab            =
=============================================*/
.iconTab {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: @colorGreyLight;
  .box-shadow(inset 0 0 0 1px @colorBorders);
}

.iconTab li {
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
}

.iconTab li a {
  font-family: @medium;
  font-size: 1rem;
  display: block;
  text-align: center;
  color: @colorTextContent;
  position: relative;
  .box-shadow(inset 0 -1px 0 0 @colorBorders);
}

.iconTab li a:hover {
  background-color: @colorGreyMid;
  color: @colorBlack;
}

.iconTab li a:after {
  background-color: @colorGreyLight;
  content: attr(data-title);
  position: absolute;
  left: -1000%;
  bottom: 0;
  width: 12rem;
  height: calc(100% + 1px);
  text-align: left;
  padding: 0 1rem;
  z-index: 1;
  .box-shadow(inset 0 0 0 1px @colorBorders);
  .transitions(left 0.4s ease-in-out);
}

.iconTab li a.active:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid @colorWhite;
  right: -1px;
  top: 50%;
  z-index: 9;
  .transform(@transformY);
}

.iconTab li:first-child a:after {
  height: 100%;
}

.iconTab li a:hover:after {
  left: 100%;
}

.iconTab li a.active {
  background-color: @colorSecondary;
  color: @colorWhite;
}

.iconTab li a.active:after {
  color: @colorBlack;
}

/*----------  Icon Links  ----------*/

a {
  color: @colorLink;
  .transitions(all 0.3s ease-in-out);
}

a:hover {
  text-decoration: none;
}

ul.iconLink {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.iconLink li {
  float: left;
}

ul.iconLink li a {
  color: @colorLink;
  .FontMedium(inherit);
  padding: 0 15px;
}

ul.iconLink li ~ li a {
  .box-shadow(inset 1px 0 0 0 @colorBorders);
}

ul.iconLink li a:hover {
  color: @colorBlack;
}

ul.iconLink li a i {
  padding-right: 0.625rem;
}

/*----------  Quick Action  ----------*/
.quickAction .list-group-item {
  font-family: @medium;
  padding: 0;
  .borderRadius(0);
}

.quickAction .list-group-item:not(:last-child) {
  margin-bottom: 0.625rem;
}

.quickAction .list-group-item a {
  padding: 0.625rem;
  color: @colorTextContent;
}

.quickAction .list-group-item a:hover {
  .box-shadow(0 0 0 1px @colorLink, 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.15);
  );
}

.quickAction .list-group-item a.active {
  background-color: lighten(@colorLink, 60%);
}

/* Avatar */

.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}

.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}

.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}

.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}

.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}

.img-avatar-thumb {
  .box-shadow(0 0 0 2px @colorWhite);
  margin: 0 5px 5px;
}

/*=====  End of Main Nav Styles  ======*/

/*=============================================
=            Body Section            =
=============================================*/
.bodyContainer {
  position: relative;
  padding-top: 0;
  height: 100%;
}



.contentScroll {
  height: calc(100% - 3.625rem);
  overflow: auto;
  scroll-behavior: smooth;
}

.contentScroll.full {
  height: 100%;
}

.customForm + .contentScroll {
  height: calc(100% - 5.875rem);
}

.breadcrumbSection + .contentScroll {
  height: calc(100% - 2.8125rem);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.flex-fill.bg-white {
  width: calc(100% - 300px);
}

/*=============================================
=            Plan Info            =
=============================================*/
.planInfoWraper {
  position: relative;
  padding: 0.5rem;
}

.planInfo,
.userData {
  background-color: @colorWhite;
  .box-shadow(inset 0 0 0 1px @colorBorders);
  padding: 0 15px;
  line-height: 2.625rem;
}

.userData {
  padding: 0.5rem 1rem;
}

.planInfo .col-auto:not(.default):first-child,
.planDistribution,
.period {
  background-color: @colorPageTitle;
  color: @colorWhite;
  width: 17.75rem;
  .ellipsis;
}

.planInfo .col-auto ~ .col-auto:not(:nth-child(2)),
.userData .col-auto ~ .col-auto {
  .box-shadow(inset 1px 0 0 0 @colorBorders);
}

.planInfo .col-auto.noLine,
.userData .col-auto.noLine {
  .box-shadow(0 0 0 0 transparent) !important;
}

.planInfo p,
.userData p {
  .FontMedium(1rem);
  margin: 0;
  padding-right: 3rem;
  line-height: normal;
}

.userData p + p {
  .FontRegular(0.875rem);
}

.userData p + div {
  .FontRegular(0.875rem);
  line-height: normal;
}

/*----------  filters  ----------*/
.filters {
  background-color: @colorFilters;
  padding: 0 15px;
  margin-bottom: 1rem;
  line-height: 2.625rem;
  position: sticky;
  top: calc(-0.5rem + 8px);
  .borderBox;
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15));
  z-index: 10;
}

.filters .custom-control-label::after,
.filters .custom-control-label::before {
  top: 50%;
  .transform(translateY(-50%));
}

.filters.buttonHolder {
  height: 3.625rem;
  background-color: rgba(@WHITE, 70%);
  border: 0;
  padding: 0 0.5rem;
  margin: 0;
  .box-shadow(none);
  .box-shadow(0 4px 16px -10px @DARK_GREY; );
}

.filters.buttonHolder > div {
  height: 100%;

  align-items: center;
}

.filters.buttonHolder > div > div {
  max-height: 38px;
}

.chioceGroup {
  height: 2.375rem;
  line-height: 2.375rem;
}

.chioceGroup label {
  margin-bottom: 0;
}

table.NgEmailTablestyle{
  border:none;
  width:630;
  border-collapse:collapse;
}
td.NgEmailtdstyle
{
  width:160;
  border:solid;
  height:20;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-wrap: normal;
}

td.NgEmailtdstyle2
{
  width:470;
  border-top:solid;
  border-left:none;
  border-bottom:solid;
  border-right:solid;
  height:20;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-wrap: anywhere;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: @colorPrimary;
  background-color: @colorPrimary;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-control {
  line-height: 1.6rem;
}

.custom-control-label {
  font-family: @medium;
  line-height: 1.6rem;
}

.planInfoWraper .filters {
  border: 0;
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15), inset 0 0 0 1px @colorGreyMid;
  );
}

/*----------  Grid Tables  ----------*/
.gridContainer {
  border: 1px solid @colorGreyMid;
}

.table-responsive:not(.noScroll) {
  padding-bottom: 0;
}

.noScroll {
  overflow: hidden;
}

.table > :not(:first-child) {
  border: 0;
}

.tblGrid {
  margin: 0;
  border-collapse: collapse;
  border: 1px solid transparent;
  border-top-color: transparent;
}

.tblGrid tr:not(:first-child),
.tblGrid.dataTable.table-bordered tr:not(:first-child) {
  border-top: 1px solid @colorGreyMid;
}

.tblGrid.fixed {
  table-layout: fixed;
}

.tblGrid.fixed th,
.tblGrid.fixed td {
  .ellipsis;
  vertical-align: middle;
}

.tblGrid.auto {
  table-layout: auto;
}

.tblGrid.auto th,
.tblGrid.auto td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.tblGrid thead th {
  .FontRegular(0.875rem);
  vertical-align: middle !important;
  border-bottom: none;
  background-color: @COOL_SAPPHIRE5;
  padding: 0.5rem;
  border-color: @colorGreyMid;
  border-top-color: @colorGreyWarm;
  color: @WHITE;
}

.tblGrid tr th:first-child {
  border-left: 1px solid @colorGreyWarm;
}

.tblGrid tr th:last-child {
  border-right: 1px solid @colorGreyWarm;
}

.tblGrid tr,
.tblGrid tr td {
  border: none;
  vertical-align: middle;
}

.tblGrid tr td i {
  font-size: 1.375rem;
  padding-right: 0.5rem;
}

.tblGrid tr td.alignField {
  padding-right: 14.8px;
}

.mappedData {
  font-size: 1.125rem;
}

.table-hover tbody tr,
.table-hover tbody tr td {
  .transitions(all 0.3s);
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover td {
  background-color: @colorGreyLight !important;
}

tr.row-deleted td {
  font-family: @bold !important;
  color: @colorGreyMid !important;
}

tr.actionable {
  cursor: pointer;
}

td.required {
  background-color: lighten(@colorError, 40%);
  .box-shadow(inset 1px 0 0 0 @colorWhite);
}

td.disabled {
  background-color: @colorGreyCool;
}

.gridContainer.fixedActions .os-viewport,
.gridContainer.fixedActions .os-content {
  position: static;
}

.gridContainer.fixedActions .os-viewport {
  width: calc(100% - 12.5rem);
}

.gridContainer.fixedActions .table-responsive th:last-child,
.gridContainer.fixedActions .table-responsive td:last-child {
  text-align: center;
  position: absolute;
  background-color: @colorGreyWarm !important;
  right: 0;
  top: auto;
  width: 12.5rem;
  line-height: 1.75;
  margin-top: -1px;
  .box-shadow(inset 0 1px 0 0 @colorGreyMid, -0.1px 0 0 0 @colorGreyMid;
  );
}

.gridContainer.fixedActions .table-responsive th:last-child {
  .box-shadow(-0.1px 0 0 0 @colorGreyMid);
}

.table-info,
.table-info > td,
.table-info > th,
.table-hover .table-info:hover,
table.dataTable tbody tr.selected,
.td-selected {
  background-color: lighten(@colorLink, 60%) !important;
}

/*----------  Data Tables  ----------*/
.dataTable {
  border: 0;
  border-collapse: collapse !important;
}

.dataTables_length .custom-select {
  width: auto;
}

.dataTables_length label {
  margin-bottom: 0;
  line-height: 2.625rem;
  padding: 0 0.5rem;
}

table.dataTable thead th,
table.dataTable thead td,
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: @colorGreyMid;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

table.dataTable thead th.no-sort {
  padding: 0.5rem;
}

table.dataTable.dt-checkboxes-select tbody tr,
table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
  cursor: pointer;
}

table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
  text-align: center;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
  margin-left: 0.5em;
}

@media screen and (max-width: 640px) {
  div.dataTables_wrapper span.select-info,
  div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display: block;
  }
}

/*----------  Pagination  ----------*/
.pagination {
  background-color: @colorGreyLight;
  margin: 0;
  border: 1px solid @colorGreyMid;
  border-top: 0;
  .borderRadius(0);
}

.pagination .pagination {
  border: none;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0;
}

.dataTables_paginate .pagination {
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  margin: 0;
  border: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button .page-link {
  padding: 0 1rem;
  border: 0;
  line-height: 2.625rem;
  text-align: center;
}

.page-link {
  font-size: 1rem;
  font-family: @medium;
  position: relative;
  background-color: transparent;
  color: @colorTextContent;
  border: 0;
  .borderRadius(0) !important;
}

.page-item.active .page-link {
  background-color: @colorWhite;
  color: @colorBlack;
  border: 0;
}

.page-link:hover {
  background-color: @colorGreyMid;
  color: @colorBlack;
  border: 0;
}

.page-item.active .page-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid @CAPGEMINI_BLUE;
}

.page-item.disabled .page-link {
  background: transparent;
  cursor: not-allowed;
  color: @colorGreyMid;
}

/*=============================================
=            Forzen Grid            =
=============================================*/

.forzenGrid {
  position: inherit;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(-n + 2),
.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 2),
.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  position: -webkit-sticky;
  position: sticky;
}

.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 2),
.forzenGrid .freezRight1.withSelect td:last-child {
  background-color: @WHITE;
}

.forzenGrid
  .freezLeft1.withSelect
  th:nth-child(n + 2):not(.freezRight1 th:last-child),
.forzenGrid
  .freezLeft1.withSelect
  td:nth-child(n + 2):not(.freezRight1 td:last-child) {
  width: 300px;
  min-width: 300px;
}

.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  width: 60px;
  max-width: 60px;
}

.forzenGrid .freezRight1 th:last-child,
.forzenGrid .freezRight1 td:last-child {
  right: 0;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(-n + 1),
.forzenGrid .freezLeft1.withSelect td:nth-child(-n + 1) {
  left: 0;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(2),
.forzenGrid .freezLeft1.withSelect td:nth-child(2) {
  left: 40px;
}

.forzenGrid .freezLeft1.withSelect th:nth-child(2):after,
.forzenGrid .freezLeft1.withSelect td:nth-child(2):after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-right: 3px solid @VIBRANT_BLUE;
}

.forzenGrid .freezRight1 th:last-child:after,
.forzenGrid .freezRight1 td:last-child:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 3px solid @VIBRANT_BLUE;
}

/*=====  End of Forzen Grid  ======*/

/*=====  End of Table Controls  ======*/

/*----------  Grid Actions  ----------*/
.gridAction {
  background-color: @colorFilters;
  line-height: 2.635rem;
  text-align: right;
  overflow: hidden;
  padding: 0 0.5rem;
  max-height: 2.625rem;
  border: 1px solid @colorBorders;
  border-bottom: 0;
}

.filters .gridAction {
  background-color: transparent;
  padding: 0;
}

ul.actionList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.form-group ul.actionList {
  min-height: 31px;
  line-height: 30px;
  padding-left: 2px;
}

ul.actionList li {
  display: inline-block;
  min-width: 2.625rem;
  text-align: center;
}

ul.actionList li + li {
  margin-left: 0.75rem;
}

ul.actionList.left li {
  margin: 0 0.75rem 0 0;
}

ul.actionList li > a {
  color: @DEEP_PURPLE;
  position: relative;
  display: block;
  .FontMedium(0.875rem);
  padding: 0 0 0 2.125rem;
  .transitions(all 0.3s);
}

ul.actionList li > a:hover {
  color: @NavendColor;
}

ul.actionList li > a i {
  background-color: @CAPGEMINI_BLUE;
  color: @colorWhite;
  position: absolute;
  left: 0;
  top: 50%;
  .transform(translateY(-50%));
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.4;
  font-size: 1.125rem;
  .borderRadius(50%);
  .box-shadow(0 0 0 2px #FFF, 1px 1px 4px -1px rgba(0, 0, 0, 0.5);
  );
}

ul.actionList li > a i.fa {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

ul.actionList li > a i[class*="done"] {
  background-color: @colorPrimary;
}

ul.actionList li > a i[class*="save"] {
  background-color: @colorSecondary;
}

ul.actionList li > a i[class*="close"],
ul.actionList li > a i[class*="delete"],
ul.actionList li > a i[class*="trash"],
ul.actionList li > a i[class*="log-out"],
ul.actionList li > a i[class*="exit"] {
  background-color: @colorError;
}

ul.actionList li > a i[class*="pdf"] {
  background-color: #f40f02;
}

ul.actionList li > a i[class*="word"] {
  background-color: #005da6;
}

ul.actionList li > a i[class*="excel"] {
  background-color: #1d6f42;
}

td ul.actionList {
  min-height: auto;
}

td ul.actionList li {
  margin: 0;
  min-width: auto;
}

td ul.actionList li > a:not(.dropdown-item) {
  padding: 0;
  width: 1.875rem;
  line-height: 1;
}

td ul.actionList li > a i {
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 1.025rem;
  .transform(translateY(-65%));
}

ul.actionList.panel li a {
  color: rgba(255, 255, 255, 0.6);
}

ul.actionList.panel li a:hover {
  color: @colorWhite;
}

.card-header ul.actionList li > a {
  color: @WHITE;
  .FontRegular(0.875rem);
}

.simpleCard .card-header ul.actionList li > a {
  color: @DEEP_PURPLE;
}

ul.actionList.noLabel li {
  min-width: inherit;
}

ul.actionList.noLabel li a {
  padding-left: 0;
}

ul.actionList.noLabel li a i {
  // // width: 1.5rem;
  // // height: 1.5rem;
  // // line-height: 1.5rem;
  // // font-size: 1.125rem;
  position: relative;
  padding: 0;
  line-height: 1.25rem;
  .transform(translateY(2px));
}

i.ion[class*="mail"]::after {
  content: "";
  display: inline-block;
  font-family: "Ionicons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

ul.actionList.noLabel li a[aria-label="Forward Email"] i::after {
  content: "";
  position: absolute;
  color: @DEEP_PURPLE;
  font-size: 1.5rem;
  top: -10px;
  left: 0;
}

ul.actionList.noLabel li a[aria-label="Resend Email"] i {
  background-color: @WARM_VIOLET4;
}

ul.actionList.noLabel li a[aria-label="Resend Email"] i::after {
  content: "";
  position: absolute;
  color: @DEEP_PURPLE;
  font-size: 1.5rem;
  bottom: -10px;
  right: 0;
  .transform(rotate(-180deg));
}

ul.actionList li > a.disabled {
  pointer-events: none;
}

ul.actionList li > a.disabled i {
  background-color: rgba(0, 0, 0, 0.3);
}

/*----------  Charts  ----------*/
.borderBox {
  border: 1px solid @colorGreyMid;
}

.noLegends .amChartsLegend {
  visibility: hidden;
}

.chartTitle {
  padding: 0;
  margin: 0;
  line-height: 2.375rem;
  .FontMedium(1rem);
  color: @CAPGEMINI_BLUE;
}

.amcharts-axis-label.categoryAxisLabel tspan {
  .FontMedium(0.875rem);
}

.amcharts-axis-label tspan,
.amcharts-legend-label tspan {
  font-size: 0.75rem;
}

.legendWrap {
  width: 500px;
}

a[href*="amcharts"] {
  display: none !important;
}

/*----------  Row Small  ----------*/
.row.small-gutter {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.row.small-gutter [class^="col-"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/*----------  Cards  ----------*/
.card-columns {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  orphans: 1;
  widows: 1;
}

.card-group {
  margin-bottom: 1rem;
}

.card {
  background: rgba(@WHITE, 100%);
  margin-bottom: 1rem;
  .borderRadius(8px);
  .box-shadow(0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.15));
}

.card .card-header,
.card .card-body,
.card .card-footer {
  .borderRadius(8px 8px 0 0);
}

.card .card-header + .card-body {
  .borderRadius(0);
}

.card .card-footer {
  .borderRadius(0 0 8px 8px);
}

.card .card-body {
  padding: 1rem;
}

.card-group .card-body {
  background-color: @colorGreyLight;
}

.card-group.bgWhite .card-body {
  background-color: @colorWhite;
}

.card .card-header {
  background-color: @COOL_SAPPHIRE5;
  color: @colorWhite;
  padding: 0 1rem;
  border: 0;
  min-height: 2.75rem;
}

.simpleCard .card-header {
  background-color: @WHITE;
  color: @colorTextContent;
  border-bottom: 1px solid @colorGreyLight;
}

.card-header h2,
.card-header h3 {
  .FontMedium(1.25rem);
  margin: 0;
  padding: 0;
  line-height: 2.625rem;
}

.card-header h3 {
  .FontMedium(1.125rem);
}

.card-body .card-title {
  color: @colorPageTitle;
  font-family: @medium;
  font-size: 1.125rem;
}

.panelAction {
  position: absolute;
  color: @colorWhite;
  font-size: 1.3125rem;
  .transitions(all 0.3s);
}

.panelAction:hover {
  color: @colorWhite;
  background-color: rgba(0, 0, 0, 0.35);
}

.card-header .panelAction {
  width: 2.625rem;
  text-align: center;
  right: 0;
}

.card-header .panelAction + .panelAction {
  right: 2.625rem;
}

.card-title {
  .FontBold(1rem);
}

.card-columns .card .card-body {
  padding: 1rem 1rem 0;
}

.card-header .badge,
.gridAction .badge,
.badge.icon {
  padding: 0.5rem 1rem;
  .FontRegular(0.75rem);
}

/*----------  Link Presence  ----------*/

.linkPresence {
  position: relative;
  float: left;
  text-align: center;
  margin-right: 1rem;
  line-height: 2.625rem;
}

.linkPresence .img-avatar {
  .box-shadow(0 0 0 2px @colorBranding);
}

.linkPresence::after {
  content: "";
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  background: @colorSuccess;
  bottom: 0.25rem;
  right: -0.5rem;
  .borderRadius(50%);
  .box-shadow(inset 0 0 0 1px @colorWhite);
}

.userInfo {
  padding: 0;
  margin: 0;
  list-style: none;
}

.userInfo li {
  line-height: 2rem;
  .ellipsis;
}

.userInfo li:not(:last-child) {
  border-bottom: 1px solid @colorGreyMid;
}

.userInfo span {
  display: inline-block;
  min-width: 6.25rem;
  .FontMedium(inherit);
}

.peopleName {
  margin: 0;
  .FontRegular(1rem);
}

.userList {
  background-color: @colorWhite;
  .borderBox;
  padding: 1rem;
  max-height: 20rem;
}

.userList > .row:not(:last-child),
.userList .os-content > .row:not(:last-child) {
  margin-bottom: 1rem;
}

.userItems {
  .borderBox;
  padding: 0.5rem;
  cursor: pointer;
  .transitions(all 0.3s ease-in-out);
}

.userItems:hover {
  background-color: @colorGreyCool;
}

.userItems .panelAction {
  background: transparent;
  color: @colorTextContent;
  width: 2.625rem;
  text-align: center;
  display: block;
}

.userItems .panelAction:hover {
  background: transparent;
  color: @colorBlack;
}

.userItems.active {
  background-color: lighten(@colorLink, 60%);
}

.userItems .linkPresence .img-avatar {
  .box-shadow(0 0 0 2px @colorBranding);
}

.userItems .media-body {
  max-width: calc(100% - 5.625rem);
}

.userName {
  font-family: @medium;
  margin: 0;
  padding: 0 0.5rem 0 0;
  .ellipsis;
}

.infoText {
  font-size: 0.75rem;
  color: @colorInfoText;
}

.userData > .row {
  min-height: 2.625rem;
}

.cellLink_ng{
  font-family: @medium;
  color: @colorLink;
  .transitions(all 0.3s);
  padding: 0.5rem 1rem;
}

/*----------  Simple Icon Link  ----------*/
.iconLink,
.cellLink {
  font-family: @medium;
  color: @colorLink;
  .transitions(all 0.3s);
}

.iconLink:hover,
.cellLink:hover {
  color: darken(@colorLink, 20%);
  cursor: pointer;
}

.iconLink .activeView {
  color: @colorBlack;
}

/*----------  Normal Lists  ----------*/
ol {
  padding: 0 0 0.75rem 0.75rem;
  margin: 0;
  list-style-position: inside;
}

ol li {
  line-height: normal;
  font-size: 0.75rem;
}

ol li ~ li {
  margin-top: 0.75rem;
}

/*----------  Tree List  ----------*/
.treeList {
  background-color: @colorGreyWarm;
  height: 100%;
  min-width: 300px;
  width: calc(300px);
  border-top: 1px solid @colorBorders;
  .box-shadow(inset 1px 0 0 0 @colorGreyLight);
}

.treeList .list-group li,
.treeList .list-group li .list-group-item {
  border: 0;
}

.treeList .list-group .treeCollapse:not(:last-child) {
  border-bottom: 2px solid @colorGreyMid;
}

.treeList .list-group li:not(:last-child) {
  border-bottom: 1px solid transparent;
}

.treeList .list-group-item {
  background-color: @colorGreyLight;
  color: @colorTextContent;
  .FontRegular(1rem);
  padding: 0.5rem 1rem;
  position: relative;
  // .box-shadow(inset -1px 0 0 0 @colorGreyMid);
  .transitions(all 0.3s ease-in-out);
}

.treeList .list-group-item:hover {
  background-color: @colorGreyMid;
  color: @colorBlack;
}

.treeList .list-group-item.active,
.treeCollapse .list-group li .list-group-item.active {
  background-color: @NavstartColor;
  color: @colorWhite;
}

.treeList .list-group-item.active:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  top: 50%;
  border-top: 8px solid transparent;
  border-right: 8px solid @WHITE;
  border-bottom: 8px solid transparent;
  .transform(@transformY);
}

.treeList .list-group-item i {
  font-size: 1.375rem;
  float: left;
  // margin: -0.25rem 0.5rem 0 0;
  width: 1.375rem;
  height: 1.375rem;
  background: @colorGreyLight;
  text-align: center;
  line-height: 1.375rem;
  border-radius: 100%;
}

.treeList .list-group-item.collapsed i::before {
  content: "";
}

.treeCollapse .list-group li .list-group-item {
  font-size: 0.875rem;
  padding-left: 2.75rem;
  background-color: @colorGreyLight;
}

.treeCollapse .list-group .list-group-item:hover {
  background-color: @colorGreyMid;
}

/*----------  Popover  ----------*/

.popover {
  max-width: 25rem;
  .borderRadius(0);
  border-color: @colorGreyMid;
  padding: 0.5rem;
  .box-shadow(0 0 1.5rem 0 rgba(0, 0, 0, 0.5));
}

.popover.size-xl {
  min-width: 40rem;
  max-width: 120rem;
}

.popover-header {
  .ellipsis;
  background-color: @colorTextContent;
  color: @colorWhite;
  font-family: @medium;
  .borderRadius(0);
}

.popover-body {
  padding: 0.5rem 0;
}

/*----------  Alert  ----------*/
.alert {
  padding: 0 1rem;
  line-height: 2.375rem;
  .borderRadius(2px);
}

.alert.alert-dismissible {
  line-height: 2rem;
  margin: 0;
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  padding: 0.75rem 1rem;
  font-size: 0.625rem;
  top: -1px;
}

.alert-dismissible + .alert-dismissible {
  margin-left: 0.5rem;
}

/*----------  Modal  ----------*/
.modal-content {
  .box-shadow(0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.35));
}

.modal-content,
.modal-header,
.modal-body {
  border: 0;
  .borderRadius(0);
}

.modal-body {
  background-color: @WHITE;
}

.modal-footer {
  .borderRadius(0);
}

.modal-header {
  background-color: @WHITE;
  color: @colorTextContent;
}

.modal-title {
  .FontBold(1rem);
}

.modal-header .close {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
  text-shadow: none;
  .transitions(all 0.3s);
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
  color: @colorWhite;
}

.modal-body.size-md {
  max-height: 25rem;
}

.modal-body_email {
  //width: 1500px !important;
  //background-color: #0d0505;
  max-height: 90%;
  max-width: 850px;
  overflow-y: auto;
  height: 100%;
}
.modal-pendingwith {
  //width: 1500px !important;
  min-width: 1200px;
}


.modal-xl {
  min-width: calc(100% - 3rem);
}

/*----------  Form Controls  ----------*/

.form-group label {
  .FontRegular(0.875rem);
  margin-bottom: 5px;
  display: block;
}

.custom-control-label {
  .FontMedium(inherit);
}

.form-inline .form-group label,
.form-inline .form-control,
.form-inline .form-group label + div {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}

.form-inline field-control > div,
.form-inline ng-select {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}

.form-inline .form-check {
  margin-bottom: 0;
}

.form-inline .form-group label,
.form-inline .form-group .form-check-label {
  line-height: 31px;
  margin-bottom: 0;
}

.form-group,
.form-inline .form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control,
.form-inline .form-group label + div,
.form-inline ng-select {
  width: calc(50% - 4px);
}

.form-inline label:not(.form-check-label)::before {
  content: ":";
  float: right;
  padding: 0 0.5rem;
}

.form-inline .form-check-input {
  margin-top: 0;
  .transform(translateY(calc(50% + 2px)));
}

.form-switch .form-check-input {
  margin-top: 0;
  .transform(translateY(calc(20% + 2px))) !important;
}

.form-inline .form-group .form-check-label {
  width: auto;
  margin-left: 0.5rem;
}

.form-control,
.input-group-text {
  .borderRadius(2px);
  border-color: @colorGreyMid;
  font-family: @medium;
}

.input-group-text {
  background-color: @colorGreyLight;
}

.rounded-pill.input-group > .btn,
.rounded-pill.input-group > .form-control,
.rounded-pill.input-group > .form-select,
.rounded-pill.input-group > .input-group-text,
.rounded-pill.input-group-sm > .btn,
.rounded-pill.input-group-sm > .form-control,
.rounded-pill.input-group-sm > .form-select,
.rounded-pill.input-group-sm > .input-group-text,
.rounded-pill.input-group-lg > .btn,
.rounded-pill.input-group-lg > .form-control,
.rounded-pill.input-group-lg > .form-select,
.rounded-pill.input-group-lg > .input-group-text {
  .borderRadius(50rem);
}

.rounded-pill.input-group > .form-control,
.rounded-pill.input-group-sm > .form-control,
.rounded-pill.input-group-lg > .form-control {
  border-right-color: @WHITE;
}

.rounded-pill.input-group .btn,
.rounded-pill.input-group-sm .btn,
.rounded-pill.input-group-lg .btn {
  border-left: 0;
}

.rounded-pill.input-group .btn i,
.rounded-pill.input-group-sm .btn i,
.rounded-pill.input-group-lg .btn i {
  padding: 0;
}

.rounded-pill .input-group-text {
  background-color: @WHITE;
}

.readonly .form-control,
.form-control-plaintext {
  .FontMedium(1rem);
}

.formData,
.formFields {
  background-color: @colorWhite;
  padding: 1rem 1rem 0;
}

.formFields,
.gridForm {
  position: relative;
  height: calc(100% - 2.625rem);
  overflow: auto;
  scroll-behavior: smooth;
}

.gridForm {
  background-color: @colorWhite;
  padding: 0.5rem;
  .box-shadow(inset 0 0 0 1px @colorGreyMid);
}

.formData label {
  margin: 0;
}

textarea {
  min-height: 31px;
}
.textarea_emailarea {
  min-height: 200px !important;
}
select {
  padding-right: 2.25rem !important;
  outline: none !important;
  .box-shadow(none) !important;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%233f3f3f%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size: 0.5rem auto, 100%;
  .borderRadius(2px);
}

.form-select {
  .borderRadius(2px);
}

.select-css::-ms-expand {
  display: none;
}

.input-group-text.currency-text,
select.currency-selector {
  background-color: @colorWhite;
  border-color: @colorBorders;
  border-radius: 0;
  outline: 0;
  height: 2rem;
}

.input-group-text.currency-text {
  font-size: 1.5rem;
}

select.currency-selector {
  width: 80px;
  padding-left: 0.313rem;
}

.badge {
  .FontRegular(0.625rem);
  letter-spacing: 0.8px;
}

.badge.icon {
  position: relative;
  padding-left: 2rem;
}

.badge.icon i {
  font-size: 1.375rem;
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  text-align: center;
  line-height: 1.375rem;
  border-radius: 100%;
  left: 0.25rem;
  top: 50%;
  .transform(@transformY);
}

label.required {
  position: relative;
}

label.required:after {
  content: "*";
  padding-left: 0.25rem;
  color: @WARM_RED4;
  .FontMedium(1rem);
}

/*----------  Custom File  ----------*/
.custom-file-label {
  text-align: left;
  margin: 0;
  .borderRadius(2px);
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  font: normal normal normal 18px/1 FontAwesome;
  content: "\f0c6";
  background: none;
  border: none;
  line-height: 2.235rem;
  color: @colorGreyMid;
  padding: 0 0.75rem;
  height: 100%;
}

.gridAction .custom-file {
  margin-top: 3px;
}

/*----------  Select 2  ----------*/

.input-group .select2-container {
  min-width: 100%;
}

.select2-container--bootstrap {
  width: 100% !important;
}

.select2-dropdown {
  .borderRadius(2px);
}

.select2-container--bootstrap .select2-selection {
  font-size: 1rem;
  font-family: @medium;
  border-color: @colorGreyMid;
  .borderRadius(2px);
  .box-shadow(none);
}

.select2-container--bootstrap
  .select2-results__option--highlighted[aria-selected] {
  background-color: @VIBRANT_BLUE;
}

.form-group-sm .select2-container--bootstrap .select2-selection--single,
.input-group-sm .select2-container--bootstrap .select2-selection--single,
.select2-container--bootstrap .select2-selection--single.input-sm {
  font-family: @medium;
  font-size: 0.875rem;
}

/*----------  Custom Select  ----------*/

.ng-select.custom {
  .FontMedium(inherit);
  border: 0px;
  min-height: 0px;
  border-radius: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder,
.ng-select .ng-select-container .ng-value-container .ng-value,
.ng-select.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  .FontMedium(0.875rem);
  color: @colorTextContent;
}

.ng-select.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  color: @colorDisabledText;
}

.ng-select .ng-select-container {
  min-height: 31px;
  padding: 0px 5px;
  border-color: @colorGreyMid;
  .borderRadius(2px);
}

.ng-select.custom .ng-select-container {
  border-radius: 0;
  width: 100%;
  .borderRadius(2px);
}

.ng-select.ng-select-multiple .ng-select-container {
  // min-height: 2.375rem;
  .borderRadius(2px);
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 0px 5px;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  .FontMedium(0.875rem);
  color: @colorTextContent;
}

.ng-dropdown-panel {
  width: auto !important;
  // max-width: 350px;
  min-width: 100%;
  border: none;
  margin-top: 0;
  margin-left: 0;
  padding: 0.5rem;
  .box-shadow(inset 0 0 0 1px @colorGreyMid,
    0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15);
  );
  z-index: 1060 !important;
  .borderRadius(0 0 2px 2px);
}

.ng-option-label {
  font-family: @medium;
}

// .form-control:focus,
// .ng-select.ng-select-opened>.ng-select-container {
//   .box-shadow(none);
//   border-color: @colorGreyMid;
//   .box-shadow(0 0 0.5rem 0 lighten(@colorSecondary, 50%));
// }

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: solid @colorGreyMid;
  border-width: 0 2px 2px 0;
  padding: 2px;
  top: 0;
  .transform(rotate(45deg));
}

.ng-select .ng-arrow-wrapper {
  line-height: 20px !important;
}

.ng-select .ng-clear-wrapper {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  right: 0;
}

.ng-select .ng-clear-wrapper .ng-clear {
  display: block !important;
  line-height: 1rem !important;
  font-size: 1rem !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  font-size: 1rem;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  max-width: 100%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  max-width: calc(100% - 2rem);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  font-size: 1rem;
  max-width: calc(100%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1.25rem;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  position: absolute;
}

/*----------  Custom Form  ----------*/

.customForm .filters,
.customForm .filters .selectedFilters {
  line-height: inherit;
  min-height: 4.75rem;
}

.customForm label {
  .FontRegular(inherit);
  font-size: 0.875rem;
  margin: 5px 0;
}

.customForm .row {
  padding-bottom: 0.5rem;
}

.customForm .form-group {
  margin-bottom: 0;
}

td > .form-group {
  margin-bottom: 0;
}

/*----------  Buttons  ----------*/

.formActions {
  text-align: right;
  padding: 6px 0;
}

.gridForm + .formActions {
  padding: 6px 0.5rem;
}

.btn {
  .borderRadius(2px);
  border-color: @colorGreyMid;
}

.btn-outline-secondary {
  border-color: @colorGreyMid;
}

.input-group > .input-group-append > .btn {
  width: 2.375rem;
}

.input-group-sm > .input-group-append > .btn {
  max-height: 31px;
  line-height: 17px;
}

.btn-outline-secondary:hover,
.gj-datepicker-bootstrap [role="right-icon"] button:hover {
  background-color: transparent;
  border-color: @colorGreyMid;
  color: @colorSecondary;
}

.gridAction .input-group > .input-group-append > .btn {
  background-color: @colorWhite;
}

/*----------  Custom Buttons  ----------*/
.btnCustom,
.btn-group-toggle .btn {
  min-width: 6rem;
  border: 0;
  max-height: 2.375rem;
  line-height: 2.375rem;
  padding: 0 1rem;
  .FontRegular(1rem);
  cursor: pointer;
  .borderRadius(2px);
  .transitions(all 0.3s ease-in-out);
}

.btnCustom.small,
.btn-group-toggle .btn {
  line-height: 1.875rem;
  padding: 0 0.875rem;
  font-size: 0.875rem;
}

.btn-group-toggle .btn ~ .btn {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 0;
}

.btn-group-toggle .btn ~ .btn,
.btn-group-toggle .btn:first-child:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-group-toggle .btn:last-child {
  border-right: 0;
}

.btnCustom i,
.btn i {
  padding-right: 0.5rem;
}

.btnCustom span + i,
.btnCustom span + span i {
  padding-left: 0.5rem;
  padding-right: 0;
}

.btnCustom:not(:first-child) {
  margin-left: 0.5rem !important;
}

.btnPrimary {
  background-color: @colorPrimary;
  color: @colorWhite;
}

.btnPrimary:focus:not([disabled]),
.btnPrimary:hover:not([disabled]) {
  background-color: @colorWhite;
  color: @colorPrimary;
  .box-shadow(inset 0 0 0 1px @colorPrimary);
}

.btnSecondary,
.btn-secondary {
  background-color: @colorSecondary;
  color: @colorWhite;
}

.btnSecondary:focus:not([disabled]),
.btnSecondary:hover:not([disabled]),
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: @colorWhite;
  color: @colorSecondary;
  .box-shadow(inset 0 0 0 1px @colorSecondary);
}

.btnNegative {
  background-color: @colorGreyMild;
}

.btnNegative:focus:not([disabled]),
.btnNegative:hover:not([disabled]) {
  background-color: @colorWhite;
  color: @colorBlack;
  .box-shadow(inset 0 0 0 1px @colorGreyMild);
}

.btnCustom:disabled,
.btnCustom[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.5px 1rem;
}

.btnCustom .badge {
  background-color: @WARM_RED4;
  position: absolute;
  top: 0;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875rem;
}

/* #region */
.progress {
  .borderRadius(0);
}

/* Dashboard */

.dashboard-status .list-unstyled {
  margin: 0;
}

.dashboard-status .list-unstyled:not(:last-child) {
  margin-bottom: 0.5rem;
}

.dashboard-status .list-unstyled .flex-fill {
  align-self: center;
}

.dashboard-status .list-unstyled li:first-child {
  background-color: @colorGreyLight;
  padding: 0.5rem;
}

.dashboard-status .list-unstyled .flex-fill a {
  display: inline-block;
  min-width: 4rem;
  text-align: right;
  padding: 0.5rem;
}

.dashboard-status .list-unstyled li + li {
  margin-left: 0.5rem;
}

div#chart-status {
  height: 257px;
}

a.collapseTrigger {
  color: @colorBlack;
  display: block;
  position: relative;
}

a.collapseTrigger > i {
  color: rgba(0, 0, 0, 0.3);
  margin-right: 0.625rem;
  .transitions(all 0.3s ease);
  .transform(rotate(0deg));
}

a.collapseTrigger.collapsed > i {
  .transform(rotate(-90deg));
}

a.collapseTrigger.collapsed > .on,
a.collapseTrigger > .off {
  margin-right: -50%;
  display: none;
  .transitions(all 0.3s ease);
}

a.collapseTrigger.collapsed > .off,
a.collapseTrigger > .on {
  margin-right: 0;
  display: inline-block;
  .transitions(all 0.3s ease);
}

/* Accordian */
.accordion-flush .accordion-body,
.accordion .accordion-body {
  padding: 0 1rem 1rem;
}

.accordion-flush .accordion-button,
.accordion .accordion-button {
  padding: 1rem 0 1rem 2rem;
}

.accordion .accordion-header {
  margin-bottom: 0;
  padding: 0 1rem;
}

.accordion-button:not(.collapsed) {
  color: @CAPGEMINI_BLUE;
  border: 0;
  background-color: @WHITE;
  .box-shadow(none);
}

.accordion-flush .accordion-button .badge,
.accordion .accordion-button .badge {
  margin-left: auto !important;
}

.accordion-flush .accordion-button::after,
.accordion .accordion-button::after {
  position: absolute;
  left: 0;
  .transform(rotate(270deg));
}

.accordion-flush .accordion-button:not(.collapsed)::after,
.accordion .accordion-button:not(.collapsed)::after {
  .transform(rotate(360deg));
}

.accordion-button.bg-danger {
  background-color: rgba(@WARM_RED4, 10%) !important;
  color: @colorTextContent !important;
}

.accordion > document:not(:first-of-type) > .accordion-item {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* #endregion */

/*----------  Offcanvas  ----------*/

.offcanvas-header {
  .box-shadow(0 -4px 16px -10px @DARK_GREY; );
}

.offcanvas-title {
  font-size: 1.25rem;
}

.offcanvas-footer {
  .box-shadow(0 4px 16px -10px @DARK_GREY; );
}

.offcanvas-body h3 {
  font-size: 1rem;
}

/*=============================================
=            Login Module            =
=============================================*/

.bodyContainer.login {
  background-color: #e5e5e5;
}

.bgWrapper {
  width: 100%;
  height: 100%;
  // background: #e5e5e5 url("../IMG/loginBackground.png") no-repeat center center;
  background: #e5e5e5 url("~src/assets/images/loginBackground.png") no-repeat
    center center;
  background-size: cover;
}

.customPosition .loginContainer {
  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loginContainer .card {
  min-width: 500px;
}

.loginContainer h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.line-image {
  position: relative;
}

.line-image::after {
  content: "";
  // background: url(../IMG/Capgemini-Line.svg) no-repeat;
  background: url("~src/assets/images/Capgemini-Line.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

/*=====  End of Login Module  ======*/

/*=====  End of Body Section  ======*/

/* Place Holders */

::-webkit-input-placeholder {
  /* Edge */
  .FontRegular(0.875rem);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  .FontRegular(0.875rem);
}

::placeholder {
  .FontRegular(0.875rem);
}

/* Scrollbar*/
::-webkit-scrollbar {
  width: 0.5rem;
  /* for vertical scrollbars */
  height: 0.5rem;
  /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

/*----------  Date Picker  ----------*/

.dropdown-menu.show {
  .box-shadow(0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.15));
  z-index: 1060;
}

.btn-light.focus,
.btn-light:focus,
.btn-light:hover,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background-color: @colorSecondary;
  color: @colorWhite;
  box-shadow: none;
  outline: 0;
}

/* Scrollbar*/
::-webkit-scrollbar {
  width: 0.5rem;
  /* for vertical scrollbars */
  height: 0.5rem;
  /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

/*----------  Loading  ----------*/
.loading {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  height: 100%;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 999999;
}

.loader {
  width: 56px;
  height: 56px;
  border: 8px solid rgba(68, 102, 119, 0.35);
  border-top-color: #007eaf;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  top: 50%;
  margin: -28px auto 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-invisible),
ul.actionList li > a:focus {
  outline: none;
  box-shadow: none;
}

/* Optional: Customize .focus-visible */
*:focus,
ul.actionList li > a:focus > i {
  outline: solid black !important;
  box-shadow: inset 0 1px 0 0 rgba(@colorLink, 0.5),
    //0 0 0 4px rgba(@VIBRANT_BLUE, 0.5) !important;
    0 0 0 4px rgba(@colorBlack, 0.5) !important;
}

/* Preview*/

.viewerContainer {
  height: calc(100% - 52px);
  margin-top: 52px;
  background: @colorGreyCool;
  overflow: auto;
}

.viewerContainer .nav-tabs {
  overflow-x: overlay;
  width: 100%;
}

.viewerContainer .nav-item {
  min-width: max-content;
}
.searchCaseOverflow{overflow-x:hidden;}


.actionList_a {
  color: @DEEP_PURPLE;
  position: relative;
  .FontMedium(1.1rem);
  padding: 0 0 0 8.125rem;
  .transitions(all 0.3s);
}

.actionList_b {

  color: @DEEP_PURPLE;

  position: relative;

  left: 80%;
  bottom: 30px;
  font-size: small;
  padding-right: 180px;
  .transitions(all 0.1s);

}
.actionList_a:hover {
  color: @NavendColor;
}
.textarea_emailarea {
  min-height: 220px !important;
  width:100% !important ;
}
